/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W listopadzie 2023 r. Transpol Lider Sp. z o.o. Sp. k. zakończył prace w ramach zadania inwestycyjnego pn. „Rozbudowa drogi powiatowej nr 2541C Balczewo – Dziennice na odcinku od km 0+000 do km 4+112,56”."), "\n", React.createElement(_components.p, null, "Zakres inwestycji obejmował między innymi:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "wykonanie rozbudowy drogi powiatowej w zakresie dostosowania szerokości jezdni i parametrów do obowiązujących warunków technicznych"), "\n", React.createElement(_components.li, null, "wyposażenie jezdni w elementy zagospodarowania zapewniające zwiększenie komfortu użytkowników i zapewnienie wymaganego poziomy bezpieczeństwa (chodniki, zatoki autobusowe, ciągi pieszo-rowerowe, ścieżki rowerowe, dojścia dla skomunikowania przyległych nieruchomości, zjazdy oraz korektę skrzyżowań)"), "\n", React.createElement(_components.li, null, "wycinkę kolidujących drzew i krzewów"), "\n", React.createElement(_components.li, null, "przebudowę rowów drogowych oraz ich lokalna reprofilacja celem zachowania ich funkcji"), "\n", React.createElement(_components.li, null, "wykonanie rowów i muld drogowych celem zapewnienia prawidłowego odwodnienia wraz z przepustami pod zjazdami w ich ciągu"), "\n", React.createElement(_components.li, null, "lokalne wykonanie sieci kanalizacji deszczowej wraz z wylotem i zrzutem wód opadowych i roztopowych do rowu poprzez przepust"), "\n", React.createElement(_components.li, null, "wykonanie doświetlenia przejść dla pieszych"), "\n", React.createElement(_components.li, null, "przebudowę infrastruktury elektroenergetycznej kolidującej z projektowanym zagospodarowaniem"), "\n", React.createElement(_components.li, null, "budowę odcinka kanalizacji deszczowej"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
